<template>
    <AddEditForm :headerTitle="$t('pages.product.editProduct')" :backTitle="$t('pages.product.backToProductPage')" :onGoBack="onGoBack" :onSave="onUpdate" :onSaveContinue="onUpdateProductAndContinue" :onDelete="onDelete">
        <template #content>
            <Accordion :headerTitle="$t('pages.product.generalInfo')" v-if="currentItem != null">
                <template v-slot:body>
                    <CForm>
                        <div role="group" class="form-group form-row">
                            <label class="col-sm-3 col-form-label">
                                {{ $t('pages.product.fromUnit') }}
                            </label>
                            <div class="col-sm-9 input-group">
                                <span>{{currentItem.fromUnit.name}}</span>
                            </div>
                        </div>


                        <div role="group" class="form-group form-row">
                            <label class="col-sm-3 col-form-label">
                                {{ $t('pages.product.toUnit') }}
                            </label>
                            <div class="col-sm-9 input-group">
                                <span>{{currentItem.toUnit.name}}</span>
                            </div>
                        </div>

                        <CInput :label="$t('pages.product.points')" :placeholder="$t('pages.product.conversionValue')"
                                v-model="currentItem.ratio" horizontal>
                        </CInput>
                    </CForm>
                </template>
            </Accordion>
     

            <div v-if="!isLoading && currentItem == null">
                {{ $t('pages.product.noDataFound') }}
            </div>
            <CSpinner color="primary" variant="grow" v-if="isLoading"></CSpinner>
            <Confirmation ref="confirmation"></Confirmation>
        </template>
       

    </AddEditForm>

</template>

<script>

    import { mapGetters, mapState, mapActions } from 'vuex'
    import Confirmation from '@/components/Confirmation.vue'
    import i18n from '@/plugins/i18n'

    import { globalConfigs } from '@/global/index'
    console.log("Global", globalConfigs);
    import Accordion from '@/components/Accordion.vue'
    import AddEditForm from '@/components/AddEditForm.vue'
    import _ from 'lodash'
    import * as yup from 'yup'

    const formSchema = yup.object().shape({
        ratio: yup.number().min(1, ({ min }) => `Nhập tỷ lệ (>${min})`)
    })

    export default {
        name: 'UnitConversionEdit',
        data() {
            return {
                currentItem: null,
                submitting: false,
                errors: {
                    ratio: "",
                },
                isLoading: true,
                isProcessing: false
            }
        },
        components: {
            Confirmation,
            Accordion,
            AddEditForm
        },
        computed: {
            ...mapState('product', ['allProducts', 'editingProduct', 'hasError', 'errorMessage', 'isLoadingProductDetail', 'unitList']),
            ...mapGetters('supplier', ['allSuppliers']),
            ...mapGetters('category', ['allCategories'])
        },
        methods: {
            ...mapActions('unit', ['updateUnitConversion','deleteConversion']),

            onGoBack() {
                this.$router.push('/units/conversions/list');
            },

            validate(field) {
                formSchema.validateAt(field, this.currentItem)
                    .then(() => this.errors[field] = '')
                    .catch((err) => {
                        this.errors[err.path] = err.message
                        console.log('validate error', err);
                    });
            },

        
            async onDelete() {
                if (!this.isProcessing && this.currentItem && this.currentItem.id > 0) {

                    this.$refs.confirmation.show(i18n.t('pages.product.confirmDelete'), async () => {
                        console.log('Delete conversion', this.currentItem);
                        var retVal = await this.deleteConversion({ id: this.currentItem.id})
                        if (retVal == true) {
                            this.onGoBack();
                        }
                    });
                }

            },

            async updateConversion(bContinueEdit) {
                if (this.isLoading || this.isProcessing || this.currentItem == null)
                    return false;

                try {
                    await formSchema.validate(this.currentItem, { abortEarly: false });
                    this.errors = {};

                    try {
                        this.isProcessing = true;
                        var result = await this.updateUnitConversion({
                            id: this.currentItem.id,
                            ratio: this.currentItem.ratio,
                        });
                        this.isProcessing = false;

                        if (result == true) {
                            if (!bContinueEdit) {
                                this.onGoBack();
                            }
                        }

                    }
                    catch {
                        this.isProcessing = false;
                    }
                }
                catch (err) {
                    this.errors = {};
                    _.forEach(err.inner, error => {
                        console.log("VALIDATION ERROR EACH:", error)
                        this.errors[error.path] = error.message;
                    })
                }
                return false;
            },
            async onUpdate() {
                await this.updateConversion(false);
            },

            async onUpdateProductAndContinue() {
                await this.updateConversion(true);
            },
          
            onCancel() {
                console.log("Cancel updating conversion.......");
                this.onGoBack();
            }
        },
        async mounted() {
            this.currentItem = await this.$unitService.getConversionById({ id: this.$route.params.id});
            this.isLoading = false;
        },
    }
</script>

<style scoped>
    .rotating {
        transition: transform 1s ease-in-out;
    }

        .rotating:hover {
            transform: rotateZ(360deg);
        }
</style>